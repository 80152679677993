<template>


  <div id="app">
    <div class="topbar">
      <img class="logo" alt="logo" src="./assets/logo.png" />
      <img class="title" alt="title" src="./assets/staking.png" />
      <button @click="connectWallet()" v-if="!accountID">CONNECT YOUR WALLET<i class="fa fa-box-archive" style="border-left: 1px solid white; padding-left: 10px; margin-left: 10px"></i></button>
      <button v-if="accountID">{{ accountID.substring(0, 10) }}...<i class="fa fa-box-archive" style="border-left: 1px solid white; padding-left: 10px; margin-left: 10px"></i></button>
    </div>

    <div class="inline">
      <div class="customDiv stake">
        <div class="top">
          <p class="title">C-01 Staking</p>
          <button
            @click="
              stakeType = 1;
              stakeList = [];
            "
            v-bind:style="{ opacity: stakeType === 1 ? '1' : '0.5' }"
          >
            C-01
          </button>
          <button
            @click="
              stakeType = 2;
              stakeList = [];
            "
            v-bind:style="{ opacity: stakeType === 2 ? '1' : '0.5' }"
          >
            C-02
          </button>
        </div>

        <p class="text text2">Each staked C-0{{ stakeType }} produces {{ stakeType == 1 ? 6 : 1 }} $C01 per day.</p>
        <div class="split"></div>
        <p class="text text3">Enter your C-0{{ stakeType }} IDs</p>

        <div class="inline2"><input v-model="Id" type="text" placeholder="" /><button @click="AddStaking()">ADD</button></div>

        <div class="inline">
          <div v-for="(id1, i) in stakeList" :key="id1" class="case">ID : {{ id1 }}<i class="fa fa-close close" @click="stakeList.splice(i, 1)"></i></div>
        </div>
        <div class="line">
          <div class="left">
            <p class="text">Quantity</p>
            <p class="text">For each C-0{{ stakeType }} Rewards Increase</p>
          </div>
          <div class="right">
            <p class="text">Staking {{ stakeList.length }} C0{{ stakeType }}</p>
            <p class="text" style="color: #bd00ff">+ {{ stakeType == 1 ? 6 : 1 }} $C01 / day</p>
          </div>
        </div>
        <button class="lastButton" v-if="stakeType == 1 && approvedC01 == false" @click="approveC01()">APPROVE C01</button>
        <button class="lastButton" v-if="stakeType == 2 && approvedC02 == false" @click="approveC02()">APPROVE C02</button>
        <button class="lastButton" v-if="stakeType == 1 && approvedC01 == true && stakeList.length >0" @click="approvestakingC01()">STAKE C01</button>
        <button class="lastButton" v-if="stakeType == 2 && approvedC02 == true && stakeList.length >0" @click="approvestakingC02()">STAKE C02</button>
      </div>

      <div class="customDiv unstake">
        <div class="top">
          <p class="title">UNSTAKE</p>
          <button @click="changeUnstakeType(1)" v-bind:style="{ opacity: unstakeType === 1 ? '1' : '0.5' }">C-01</button>
          <button @click="changeUnstakeType(2)" v-bind:style="{ opacity: unstakeType === 2 ? '1' : '0.5' }">C-02</button>
        </div>

        <p class="text text2">Select the token you would like to unstake.</p>
        <div class="split"></div>
        <p class="text text3">C-0{{ unstakeType }} IDs</p>
        <div class="inline">
          <div v-for="(id, j) in unstakeList" :key="id" class="case">ID : {{ id }}<i class="fa fa-close close" @click="unstakSplice(j)"></i></div>
        </div>

        <div class="line">
          <div class="left">
            <p class="text">Quantity</p>
            <p class="text">C-0{{ unstakeType }} Rewards/Day</p>
          </div>
          <div class="right">
            <p class="text">Staking {{unstakeList.length}} C0{{ unstakeType }}</p>
            <p class="text" style="color: #bd00ff">+ {{ (unstakeType == 1 ? 6 : 1)*unstakeList.length }} $C01 / day</p>
          </div>
        </div>

        <button class="lastButton" v-if="stakeList != []" @click="unstake()">unstake</button>
      </div>
    </div>

    <div class="inline">
      <div class="customDiv claiming">
        <p class="title">CLAIMING REWARDS</p>
        <p class="text text2">You will be able to claim your tokens after the marketplace launch.</p>

        <div class="line2">
          <div class="left">
            <p class="text" style="font-size: 30px">{{ time }} $C01</p>
            <p class="text" style="opacity: 0.7">Pending Rewards</p>
          </div>
          <div class="middle">
            <p class="text"><i class="fa fa-circle-arrow-right" style="font-size: 50px"></i></p>
          </div>
          <div class="right">
            <p class="text" style="font-size: 30px">SOON</p>
            <p class="text" style="opacity: 0.7">Balance</p>
          </div>
        </div>

  
      <button style="width: 50%; margin-left: 25%">CLAIM COMING SOON</button>
  </div>
    </div>
  </div>
  
</template>

<script>
var Web3 = require("web3");
import abis from "./abis/C01.json";
import abisOne from "./abis/C02.json";
import abiStaking from "./abis/STAKING.json";

export default {
  name: "Mint",
  data() {
    return {
      address: "",
      addressOne: "",
      addressStaking: "",
      accountID: "",
      accountBalance: 0,
      abi: [],
      abiOne: [],
      abiStaking: [],
      contract: [],
      contractOne: [],
      contractStaking: [],
      wlClaimed: 0,
      // Contract
      isActive: false,
      isPresaleActive: false,
      currentSupply: 0,
      totalTokens: 9777,
      maxSupply: 9777,
      buyLimit: 2,
      nftCount: 0,
      claimCount: 0,
      whiteListMaxMint: 2,
      notAllowed: false,
      // Form data
      nftsCountToMint: 2,
      minted: false,
      isMinting: false,
      checkId: 0,
      claimable: "",
      condition: false,
      stakeType: 1,
      unstakeType: 1,
      Id: "",
      approvedC01: false,
      approvedC02: false,
      stakeList: [],
      time: 0,
      unstakeList: [],
    };
  },
  async created() {
    await this.loadWeb3();
  },
  methods: {
    goToExternal(url) {
      window.open(url);
    },
    rmCount() {
      if (this.nftsCountToMint > 1) {
        this.nftsCountToMint--;
      }
    },
    async changeUnstakeType(a) {
      console.log(a);
      this.unstakeType = a;
      if (this.unstakeType == 1) {
        this.unstakeList = await this.contractStaking.methods.getStakedC01(this.accountID).call({
          from: this.accountID,
        });
      } else {
        this.unstakeList = await this.contractStaking.methods.getStakedC02(this.accountID).call({
          from: this.accountID,
        });
      }
    },
    async AddStaking() {
       if (!this.accountID) {
        alert("please connect your wallet");
        return;
      }
      try {
        if (this.stakeType == 1) {
          let address = await this.contract.methods.ownerOf(parseInt(this.Id)).call({
            from: this.accountID,
          });

          if (address.toLowerCase() != this.accountID) {
            alert("you're not owner of id ", this.Id);
            return;
          }
        } else {
          let address = await this.contractOne.methods.ownerOf(parseInt(this.Id)).call({
            from: this.accountID,
          });
          if (address.toLowerCase() != this.accountID) {
            alert("you're not owner of id ", this.Id);
            return;
          }
        }
        if (parseInt(this.Id) < 8888) {
          this.stakeList.push(parseInt(this.Id));
          this.stakeList = this.stakeList.filter((c, index) => {
            return this.stakeList.indexOf(c) === index;
          });
        } else {
          window.alert("this id doesn't exist");
        }
      } catch (e) {
        console.log(e);
        window.alert("this id doesn't exist");
      }
    },

    addcount() {
      if (this.nftsCountToMint < 5) {
        this.nftsCountToMint++;
      }
    },
    removeItemOnce(arr, value) {
      var index = arr.indexOf(value);
      if (index > -1) {
        arr.splice(index, 1);
      }
      return arr;
    },
    unstakSplice(i) {
      this.unstakeList = this.unstakeList.filter((word) => word != this.unstakeList[i]);
    },
    async loadWeb3() {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);

        window.ethereum.on("accountsChanged", async (accounts) => {
          await this.setWallet(accounts[0]);
        });
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
      } else {
        window.alert("Non-Ethereum browser detected. You should consider trying MetaMask !");
      }

      await this.loadContractData();
      setInterval(
        function () {
          this.loadContractData();
        }.bind(this),
        1000
      );
    },
    async approveC01() {
      if (!this.accountID) {
        alert("please connect your wallet");
        return;
      }
      await this.contract.methods
        .setApprovalForAll(this.addressStaking, true)
        .send({
          from: this.accountID,
          value: 0,
        })
        .on("receipt", function (res) {
          console.log(res);
        });
      this.loadContractData();
    },
    async approveC02() {
      if (!this.accountID) {
        alert("please connect your wallet");
        return;
      }
      await this.contractOne.methods
        .setApprovalForAll(this.addressStaking, true)
        .send({
          from: this.accountID,
          value: 0,
        })
        .on("receipt", function (res) {
          console.log(res);
        });
      this.loadContractData();
    },
    async pick(nftsCountToMint) {
      this.nftsCountToMint = nftsCountToMint;
      console.log(this.nftsCountToMint);
    },
    async loadContractData() {
      const web3 = window.web3;
      const networkId = await web3.eth.net.getId();

      if (networkId !== abis.network) {
        window.alert("Please change to ethereum mainnet.");
        return;
      }

      this.abi = abis.abi;
      this.address = abis.address;
      this.addressOne = abisOne.address;
      this.abiStaking = abiStaking.abi;
      this.addressStaking = abiStaking.address;
      this.contractStaking = new web3.eth.Contract(this.abiStaking, this.addressStaking);

      this.contract = new web3.eth.Contract(this.abi, this.address);
      this.nftCount = await this.contract.methods.balanceOf(this.accountID).call();
      this.abiOne = abisOne.abi;
      this.contractOne = new web3.eth.Contract(this.abiOne, this.addressOne);
      this.approvedC01 = await this.contract.methods.isApprovedForAll(this.accountID, this.addressStaking).call({
        from: this.accountID,
      });
      this.approvedC02 = await this.contractOne.methods.isApprovedForAll(this.accountID, this.addressStaking).call({
        from: this.accountID,
      });
      if (this.unstakeType == 1) {
        this.unstakeList = await this.contractStaking.methods.getStakedC01(this.accountID).call({
          from: this.accountID,
        });
      } else {
        this.unstakeList = await this.contractStaking.methods.getStakedC02(this.accountID).call({
          from: this.accountID,
        });
      }
    
    this.time=await this.contractStaking.methods.getStakerFullBalanceC01(this.accountID).call({
                from: this.accountID
              })*6

      this.time+=parseInt(await this.contractStaking.methods.getStakerFullBalanceC02(this.accountID).call({
                from: this.accountID
              }))



    this.time=parseInt(this.time/86400)

  
    },
    async unstake() {
      if (this.unstakeType == 1) {
        await this.contractStaking.methods
          .unstakeC01ByIds(this.unstakeList)
          .send({
            from: this.accountID,
            value: 0,
          })
          .on("receipt", function (res) {
            console.log(res);
          });
      } else {
        await this.contractStaking.methods
          .unstakeC02ByIds(this.unstakeList)
          .send({
            from: this.accountID,
            value: 0,
          })
          .on("receipt", function (res) {
            console.log(res);
          });
      }

      await this.loadContractData();
    },
    async setWallet(address) {
      this.accountID = address;
    },
    async connectWallet() {
      console.log("Connect to wallet");
      const web3js = window.web3;
      if (typeof web3js !== "undefined") {
        this.web3 = new Web3(web3js.currentProvider);
        const accounts = await window.ethereum
          .request({
            method: "eth_requestAccounts",
          })
          .catch((err) => {
            alert(err.message);
          });
        await this.setWallet(accounts[0]);
        await this.loadContractData();
      } else {
        // web3 = new Web3(new Web3.providers.HttpProvider('http://localhost:7545')) GANACHE FALLBACK
        alert("Unable to connect to Metamask");
      }
    },
    async check() {
      console.log("Connect to wallet");
      console.log(this.checkId);
      this.condition = await this.contractOne.methods.claimed(this.checkId).call();
      this.claimable = this.condition ? "C01 #" + this.checkId + " has been claimed" : "C01 #" + this.checkId + "  is claimable";
    },
    async approvestakingC01() {
      if (!this.accountID) {
        alert("please connect your wallet");
        return;
      }
      
      await this.contractStaking.methods.stakeC01(this.stakeList).send({
        from: this.accountID,
        value: 0,
      });
    },
    async approvestakingC02() {
      if (!this.accountID) {
        alert("please connect your wallet");
        return;
      }

      await this.contractStaking.methods.stakeC02(this.stakeList).send({
        from: this.accountID,
        value: 0,
      });
      await this.loadContractData();
      this.stakeList = [];
    },
    async claim() {
      await this.contractOne.methods
        .claim()
        .send({
          from: this.accountID,
          value: 0,
        })
        .on("receipt", function (res) {
          window.location.href = "https://twitter.com/intent/tweet?url=https://opensea.io/collection/c-01-official-collection&text=I%20claimed%20my%20C-02%20by%20holding%20C-01%20NFT!%20%F0%9F%94%A5%20%0AClaim%20it%20now!%20%40C_01_Project%0A%20%23C02%20%23NFT%0A";
          console.log("Receipt :", res);
        })
        .on("error", function (err) {
          console.log("error:" + err);
          alert("Transaction Error");
        });
      this.claimCount = await this.contractOne.methods.unClaimed().call({
        from: this.accountID,
      });
    },
    //Minting Functionality
    async mint(e) {
      this.isMinting = true;
      e.preventDefault();
      this.accountBalance = await window.web3.eth.getBalance(this.accountID);
      if (this.accountID === "") {
        window.alert("Please connect wallet first!");
        this.isMinting = false;
        return;
      } else if (this.accountBalance <= this.nftPrice * this.nftsCountToMint) {
        this.isMinting = false;
        alert(`Insufficient funds`);
        return;
      }

      this.isActive = await this.contract.methods.isActive().call();
      this.isPresaleActive = await this.contract.methods.isPresaleActive().call();
      console.log("isActive : ", this.isActive);
      console.log("isPresaleActive : ", this.isPresaleActive);

      if (!this.isActive) {
        this.isMinting = false;
        alert("Sale is not active yet!");
        return;
      }

      const noOfTokens = this.nftsCountToMint;
      console.log("nftPrice : ", this.nftPrice);
      if (this.isPresaleActive == true) {
        this.whiteListMaxMint = await this.contract.methods.WHITELIST_MAX_MINT().call();
        this.wlClaimed = parseInt(await this.contract.methods.whiteListClaimed(this.accountID).call());

        if (this.wlClaimed + this.nftsCountToMint > this.whiteListMaxMint) {
          alert(`Already minted ${this.wlClaimed} but max is ${this.whiteListMaxMint}`);
          this.isMinting = false;
          //this.notAllowed = true;
          return;
        }

        console.log("whiteListMaxMint : ", this.whiteListMaxMint);
        if (noOfTokens < 1 || noOfTokens == undefined) {
          alert("Select at least 1 NFT!");
        } else if (noOfTokens > this.whiteListMaxMint) {
          alert("Buy limit for presale is : " + this.whiteListMaxMint);
          this.notAllowed = true;
        } else if (this.totalSupply >= this.totalTokens) {
          alert("Sold out!");
        } else {
          const proof = await this.GetMerkleProof(this.accountID);
          if (proof.length == 0) {
            //alert("This wallet is not Whitelisted");
            this.notAllowed = true;
          } else {
            const result = await this.contract.methods
              .mintNFTDuringPresale(noOfTokens, proof)
              .send({
                from: this.accountID,
                value: parseInt(this.nftPrice) * noOfTokens,
              })
              .on("receipt", function (res) {
                this.minted = true;
                this.isMinting = false;
                console.log("Receipt :", res);
              })
              .on("error", function (err) {
                this.isMinting = false;
                console.log("error:" + err);
                alert("Transaction Error");
              });
            this.minted = true;
            console.log("Test :", result);
          }
        }
      } else {
        if (noOfTokens < 1 || noOfTokens == undefined) {
          alert("Select at least 1 NFT!");
        } else if (this.totalSupply >= this.currentSupply) {
          alert("Sold out!");
        } else {
          const result = await this.contract.methods
            .mintNFT(noOfTokens)
            .send({
              from: this.accountID,
              value: parseInt(this.nftPrice) * noOfTokens,
            })
            .on("receipt", function (res) {
              this.minted = true;
              this.isMinting = false;
              console.log("Receipt :", res);
            })
            .on("error", function (err) {
              this.isMinting = false;
              console.log(err);
              alert("Transaction Error");
            });
          this.minted = true;
          console.log("Test :", result);
        }
      }
      this.isMinting = false;
    },
  },
};
</script>

<style lang="scss">
//https://www.figma.com/proto/EGODF1qI6AXtIONiWFKcuf/C-01_STAKE?page-id=0%3A1&node-id=2%3A2&scaling=min-zoom
//@import url(//db.onlinewebfonts.com/c/917a69d24b88e2d4d2ca803c0605bd59?family=CeraPRO-Regular);
//@include font("CeraPRO", "./assets/cera-pro/Cera Pro Regular.otf");
@font-face {
  font-family: "CeraPRO";
  src: url("./assets/cera-pro/Cera Pro Regular.otf") format("opentype");
}

html,
body,
#app {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  background-color: black;
  zoom: normal;
}

$color1: #c951ee;
$color2: #eeb7ff;

#app {
  font-family: "CeraPRO";
  text-align: center;
  color: white;
  margin: auto;
  overflow-x: hidden;
  background-color: black;
  background-image: url("./assets/bg.jpg");
  background-size: cover;
  min-height: calc(100vh);
}

.text {
  // font-style: normal;
  // font-weight: 500;
  // font-size: 20px;
  // line-height: 25px;
  // color: #ffffff;
  // opacity: 1;
}

.title {
  font-family: "CeraPRO";
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 38px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: $white;
  text-shadow: 0px 0px 6px #ffffff;
}

button {
  font-family: "CeraPRO";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 25px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 10px;
  border: 2px solid #c951ee;
  background: linear-gradient(230.18deg, rgba(143, 0, 255, 0.2) 22.19%, rgba(176, 75, 255, 0.2) 79.17%),
    radial-gradient(64.07% 192.26% at 22.5% 71.85%, rgba(158, 98, 167, 0.2) 0%, rgba(46, 46, 63, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border-radius: 3px;
  color: $white;
  cursor: pointer;
  &:hover {
    background: linear-gradient(230.18deg, rgba(144, 0, 255, 0.367) 22.19%, rgba(177, 75, 255, 0.433) 79.17%),
      radial-gradient(64.07% 192.26% at 22.5% 71.85%, rgba(158, 98, 167, 0.2) 0%, rgba(46, 46, 63, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  }
}

.split {
  width: 100%;
  margin: auto;
  border-top: 1px solid $white;
  height: 1px;
}

input {
  background: #110059;
  border: none;
  color: $white;
  font-size: 20px;
  padding: 10px;
  width: calc(100% - 20px);
}

.topbar {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 25px;
  //border: 1px solid red;
  .logo {
    height: 100px;
    object-fit: contain;
    margin-left: 50px;
    margin-top: 25px;
  }
  .title {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);

    height: 50px;
    object-fit: contain;
    margin-top: 50px;
  }
  button {
    margin-right: 50px;
    height: 50px;
    margin-top: 50px;
  }
}

.inline {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.customDiv {
  position: relative;
  text-align: left;
  padding: 0px 50px;
  border-radius: 5px;
  border: 2px solid #c951ee;
  .top {
    display: flex;
    margin-top: -10px;
    button {
      margin-left: 25px;
    }
  }
  .text2 {
    //margin-bottom: 50px;
    font-size: 21px;
    margin-top: -10px;
  }
  .inline2 {
    //border: 1px solid red;
    margin-bottom: -20px;
    input {
      width: calc(80% - 50px);
      margin-right: 25px;
    }
    button {
      width: 20%;
    }
  }
  .text3 {
    margin-top: 30px;
    margin-bottom: 0px;
    font-size: 21px;
    //color: red;
  }
  button {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .line {
    display: flex;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 60px;
    //border: 1px solid red;

    .left {
      text-align: left;
      width: 50%;
      .text {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        color: #ffffff;
        opacity: 0.7;
      }
    }
    .right {
      text-align: right;
      width: 50%;
      .text {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }
  .line2 {
    display: flex;
    width: 100%;
    margin-top: -40px;
    margin-bottom: 60px;
    .left {
      text-align: left;
      width: 50%;
      .text {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        color: #ffffff;
        opacity: 1;
      }
    }
    .right {
      text-align: right;
      width: 50%;
      .text {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        color: #ffffff;
        opacity: 1;
      }
    }
  }
  .lastButton {
    position: absolute;
    bottom: -5px;

    // margin-top: 0;
    // margin-left: 25%;
    width: 50%;
    // margin-bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.stake {
  min-width: 350px;
  width: 33%;
  margin-right: 25px;
  margin-left: 25px;
  margin-bottom: 25px;
  min-height: 420px;
    .case {
      background: #110059;
      color: $white;
      font-size: 20px;
      padding: 10px;
      margin-right: 5px;
      .close {
        margin-left: 10px;
        font-size: 15px;
        cursor: pointer;
        &:hover {
          color: #c951ee;
        }
      }}
}

.unstake {
  min-width: 350px;
  width: 33%;
  margin-right: 25px;
  margin-left: 25px;
  margin-bottom: 25px;
  min-height: 420px;

  .inline {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .case {
    background: #110059;
    color: $white;
    font-size: 20px;
    padding: 10px;
    margin-right: 5px;
    .close {
      margin-left: 10px;
      font-size: 15px;
      cursor: pointer;
      &:hover {
        color: #c951ee;
      }
    }
  }
}

.claiming {
  min-width: 350px;
  width: 35%;
  //margin: auto;
  //margin-bottom: 50px;
  margin-right: 25px;
  margin-left: 25px;
  .text2 {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: $layout-breakpoint-xlarge) {
  .topbar {
    width: 100%;
    justify-content: space-between;
    .title {
      display: none;
    }
  }

  .customDiv {
    padding: 0 15px;
    .lastButton {
      width: 90%;
    }
  }

  // .stake {
  //   min-height: 500px;
  // }
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .stake,
  .unstake,
  .claiming {
    margin-bottom: 50px;
    min-width: 250px;
    width: 90%;
  }
}

@media screen and (max-width: $layout-breakpoint-large) {
  .topbar {
    width: 100%;
    justify-content: center;
    .logo {
      display: none;
    }
    .title {
      display: none;
    }
    button {
      width: calc(100% - 50px);
      max-width: 350px;
      margin: auto;
      margin-top: 50px;
      .fa-box-archive {
        display: none;
      }
    }
  }
}
</style>
